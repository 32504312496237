var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"hxImage",class:['hx-image',
    (_vm.round && 'round'),
    _vm.position,
    _vm.isLoadingImage && 'loading',
    (_vm.isBlock && 'block'),
    (_vm.height && _vm.width) ? 'adjust' : ''
  ],style:(_vm.hxImageStyle),on:{"click":_vm.doClick}},[(!_vm.isError)?_c('img',{ref:"img",staticClass:"img",style:(_vm.imageStyle),attrs:{"src":_vm.imgSrc,"alt":""}}):_vm._e(),(_vm.isError)?_c('div',{staticClass:"pad-img-error",style:(_vm.loadingStyle),domProps:{"innerHTML":_vm._s(_vm.alt)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }