
/*
 * @Description  : 标配常量
 */

// 公司网站logo
export const logoUrl = 'https://static.anransafety.com/image/logo/img-anran-logo-text.png'

// 公司新闻列表
export const companyNewsList = []

// 公司法律法规列表
export const laws = [
  { title: '深圳市生产经营单位安全生产主体责任规定', date: '2023-05-23', path: '/article/1001?type=laws' },
  { title: '应急管理部令第10号工贸企业重大事故隐患判定标准', date: '2023-05-23', path: '/article/1002?type=laws' }
]

export const navMenus = [
  { path: '/home', name: '首页' },
  {
    path: '',
    name: '核心业务',
    children: [
      {
        name: '安全咨询',
        path: '',
        children: [
          { name: 'HSE 管理合规性评估', path: '/article/1001' },
          { name: '安全战略规划与文化建设', path: '/article/1002' },
          { name: 'HSE 管理体系建设', path: '/article/1003' },
          { name: '双重预防机制', path: '/article/1004' },
          { name: '安全生产标准化', path: '/article/1015' },
          { name: 'HAZOP分析与SIL评估', path: '/article/1016' },
          { name: '应急预案编制与实战演练', path: '/article/1005' },
          { name: '第三方安全检查与隐患排查', path: '/article/1006' },
          { name: '安全技术人员驻场服务', path: '/article/1007' }
        ]
      },
      {
        name: '安全咨询',
        path: '',
        children: [
          { name: '定制化安全培训', path: '/article/1008' },
          { name: '安全文化活动策划及实施', path: '/article/1009' },
          { name: '安全宣教材料设计与制作', path: '/article/1010' },
          { name: '安全教育视频、期刊及出版物', path: '/article/1011' }
        ]
      },
      {
        name: '安全设备',
        path: '',
        children: [
          { name: '自动体外除颤器AED', path: '/article/1012' },
          { name: '正压式空气呯吸器、多合一气体检测仪', path: '/article/1013' }
        ]
      }
    ]
  },
  {
    path: '',
    name: '资讯',
    children: [
      {
        name: '公司新闻',
        path: '/company-news?v=1.0'
      },
      {
        name: '法律法规',
        path: '/laws?v=1.0'
      }
    ]
  },
  { path: '/about-us', name: '关于我们' },
  { path: '/contact-us', name: '联系我们' }
]
