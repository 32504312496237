<template>
  <div class="pad-home hx-main top">
    <carousel :height="bannerHeight" :interval="5000" arrow="always">
      <carousel-item v-for="(item, idx) in banners" :key="idx">
        <div class="pad-carousel-contain">
          <img :style="item.bgStyle" :src="item.src" alt="" class="img-banner">
          <img :style="`height: ${bannerHeight/2.5}px;`" :src="item.textSrc" alt="" :class="['img-banner-text', item.position]">
          <h1 class="title">{{ item.title }}</h1>
        </div>
      </carousel-item>
    </carousel>
    <div class="pad-guide-row" :style="`height: ${pageHeight}px`">
      <img src="./../assets/img/img-home-about-us.jpg" alt="" class="img-side">
      <div class="pad-guide">
        <div class="pad-detail">
          <h1 class="title">关于我们</h1>
          <h3 class="subtitle">ABOUT US</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市安然安全科技有限公司成立于2017年，是一家专业化第三方安全技术服务机构，现为深圳市政府采购中心合格供应商，长期为中海油、中交、中航、华润等单位提供服务，例如：安全管理合规性评估、安全管理体系建设、安全生产标准化建设、安全风险管控、安全检查与隐患治理、应急预案编制、应急演练、HAZOP分析及SIL评估、安全信息化平台开发、安全培训、专家驻场等服务。
          </p>
          <button class="btn-to-next" @click="$toRoute('/about-us')">查看更多 ></button>
        </div>
      </div>
    </div>
    <div class="hx-container" style="margin: 32px auto;">
      <div class="hx-title">公司资质</div>
      <span class="hx-subtitle">QUALIFICATIONS</span>
      <p class="hx-article" style="line-height: 2">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司成立以来，通过自身不懈的努力和广大客户的信赖，获得如下殊荣：“广东省守合同重信用企业 、AAA级企业信用等级证书 、深圳市科技型中小企业、ISO9001质量管理体系认证证书 、ISO45001职业健康安全管理体系认证证书 、ISO14001环境管理体系认证证书 ”
      </p>
      <certification></certification>
      <div class="hx-title">合作伙伴</div>
      <span class="hx-subtitle">PARTNERS</span>
      <partners></partners>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import partners from '../components/partners.vue'
import certification from '../components/certification.vue'
import { Carousel, CarouselItem } from 'element-ui'
export default {
  name: 'home',
  components: {
    Carousel,
    CarouselItem,
    partners,
    certification
  },
  data () {
    return {
      pageHeight: 0,
      bannerHeight: '0px',
      banners: [
        { position: 'left', bgStyle: 'opacity: .6;', src: `${this.$cos}banner/img-banner-1-new.jpg`, textSrc: `${this.$cos}banner/img-banner-text-1.png` },
        { position: 'center', bgStyle: 'opacity: .4;', src: `${this.$cos}banner/img-banner-2-new.jpg`, textSrc: `${this.$cos}banner/img-banner-text-2.png` }
      ]
    }
  },
  mounted () {
    this.$_init()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.$_getBannerHeight)
  },
  methods: {
    $_init () {
      this.pageHeight = document.body.clientWidth < 720 ? 320 : document.body.clientHeight
      this.$_getBannerHeight()
      window.addEventListener('resize', this.$_getBannerHeight)
    },
    $_getBannerHeight () {
      this.bannerHeight = this.pageHeight + 'px'
    }
  }
}
</script>
<style lang="less" scoped>
@import "./index.less";
.pad-home {
  top: 0;
  position: relative;
  .pad-carousel-contain {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    background-color: white;
    .title {
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      color: white;
      font-weight: 600;
      text-shadow: 0 0 4px rgba(0,0,0,.3);
    }
  }

}

@media screen and (min-width: 720px) {
  .pad-carousel-contain {
    .title {
      font-size: 50px;
    }
    .img-banner {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .img-banner-text {
      position: absolute;
      width: auto;
      &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.left {
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
      }
    }
  }
  .pad-guide-row {
    background-color: black;
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    .img-side {
      width: 100%;
      height: auto;
      display: block;
    }
    .pad-guide {
      position: absolute;
      flex: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #f2f2f2;
      top: 50%;
      transform: translateY(-50%);
      left: 10%;
      width: 50%;
      .subtitle {
        font-weight: 300;
      }
      .pad-detail {
        width: 80%;
        line-height: 2;
        p {
          font-size: 18px;
        }
      }
      .btn-to-next {
        height: 44px;
        padding: 0 @pm-md;
        border-radius: 4px;
        font-size: 15px;
        margin-top: @pm-md;
        margin-bottom: @pm-bg;
        background-color: #ccc;
        border: none;hx-title
        &:hover {
          background-color: white;
        }
      }
    }
  }

}

@media screen and (max-width: 720px) {
  .pad-carousel-contain {
    .title {
      font-size: 20px;
    }
  }
  .pad-guide-row {
    background-color: black;
    display: block;
    width: 100%;
    overflow: hidden;
    height: 420px!important;
    position: relative;
    .img-side {
      width: auto;
      height: 100%;
      display: relative;
      left: -15%;
    }
    .pad-guide {
      position: absolute;
      flex: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #f2f2f2;
      top: 50%;
      transform: translateY(-50%);
      left: 5%;
      width: 90%;
      .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: @pm-sm;
      }
      .subtitle {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: @pm-sm;
      }
      .pad-detail {
        width: 90%;
        line-height: 1.4;
        text-align: center;
        p {
          text-align: justify;
        }
      }
      .btn-to-next {
        height: 34px;
        border: none;
        padding: 0 @pm-md;
        border-radius: 4px;
        font-size: 14px;
        margin-top: @pm-sm;
        margin-bottom: @pm-md;
        background-color: #ccc;
      }
    }
  }

}
</style>
