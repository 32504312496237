<!--
 * @Description  : 合作伙伴版图
-->
<template>
  <section class="pad-partners-wall">
    <div class="card-partner" v-for="(item, idx) in partners" :key="'partner' + idx">
      <div class="zone-image">
        <hx-image class="img-logo" :src="item.src" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import HxImage from './HxImage.vue'
export default {
  data () {
    return {
      partners: []
    }
  },
  components: {
    HxImage
  },
  mounted () {
    this.$_init()
  },
  methods: {
    $_init () {
      this.partners = []
      for (let i = 1; i <= 20; i++) {
        this.partners.push({
          src: `${this.$cos}logo/img-corp-logo-${i}.png`
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/variables.less";

@media screen and (min-width: 720px) {
  .pad-partners-wall {
    width: 100%;
    background-color: white;
    color: #ccc;
    cursor: default;
    font-size: 14px;
    padding: 0 0% @pm-lg*3;
    .card-partner {
      width: 20%;
      display: inline-block;
      padding: @pm-sm @pm-md;
      .zone-image {
        border-radius: 4px;
        display: block;
        border: 1px solid #f2f2f2;
        height: 100px;
        width: 100%;
        padding: @pm-md;
        transition: transform .4s;
        &:hover {
          transform: scale(1.1, 1.1);
        }
      }
      .img-logo {
        height: 100%;
        width: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .pad-partners-wall {
    width: 100%;
    background-color: white;
    color: #ccc;
    cursor: default;
    font-size: 14px;
    padding: 0 @pm-sm @pm-md;
    .card-partner {
      width: 50%;
      display: inline-block;
      padding: @pm-sm;
      .zone-image {
        border-radius: 4px;
        height: 100%;
        display: block;
        border: 1px solid #f2f2f2;
        height: 100px;
        width: 100%;
        padding: @pm-lg @pm-sm;
      }
      .img-logo {
        height: 100%;
        width: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
