<!--
 * @Description  : 全屏布局文件
-->
<template>
  <div class="pad-main-layout" @scroll="doScroll" ref="mainLayout">
    <smart-nav :class="scrollTop > 80 ? 'scroll' : ''"></smart-nav>
    <router-view></router-view>
    <copyright></copyright>
  </div>
</template>

<script>
import SmartNav from './../components/nav/index.vue'
import copyright from '../components/copyright.vue'
export default {
  components: {
    SmartNav,
    copyright
  },
  data () {
    return {
      scrollTop: 0
    }
  },
  methods: {
    doScroll ($event) {
      const $view = this.$refs.mainLayout
      this.scrollTop = $view.scrollTop
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/index.less";
.pad-main-layout {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: block;
}
</style>
