import Vue from 'vue'
import { Image } from 'element-ui'
import App from './App.vue'
import router from './router'

Vue.component(Image.name, Image)

Vue.config.productionTip = false
Vue.prototype.$cos = 'https://static.anransafety.com/image/'
Vue.prototype.$toRoute = (path, isReplace = false) => {
  isReplace ? router.replace(path) : router.push(path)
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
