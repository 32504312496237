import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: home
      },
      {
        path: '/about-us',
        component: () => import(/* webpackChunkName: "about-us" */ '../views/about-us.vue')
      },
      {
        path: '/contact-us',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/contact-us.vue')
      },
      {
        path: '/news',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/news/index.vue')
      },
      {
        path: '/company-news',
        component: () => import(/* webpackChunkName: "articles" */ '../views/company-news.vue')
      },
      {
        path: '/laws',
        component: () => import(/* webpackChunkName: "articles" */ '../views/laws.vue')
      },
      {
        path: '/article/:id',
        component: () => import(/* webpackChunkName: "article" */ '../views/article.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
