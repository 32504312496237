<!--
 * @Description  : 导航栏
-->
<template>
  <div class="hx-nav">
    <div class="img-logo" @click="$router.push('/')">
      <img :src="logoUrl" alt="" class="icon">
    </div>
    <div class="pad-options">
      <div class="pad-first-level" v-for="(option, index) in options"
        :key="`first-level-` + index">
        <button @click="toLink(option)" :class="['btn-menu first-level', $_isSelected(option) && 'selected']">{{ option.name }}</button>
        <div class="pad-second-level" v-if="option.children && option.children.length">
          <div class="menu second-level"
            v-for="(item, idx) in option.children"
            :key="`second-level-` + idx">
            <button :class="['btn-menu second-level', item.path && 'link']"
              @click="toLink(item)">
              {{ item.name }}
            </button>
            <div class="pad-third-level" v-if="item.children && item.children.length">
              <button v-for="(subItem, key) in item.children"
                :key="`third-level-` + key"
                @click="toLink(subItem)"
                :class="['btn-menu third-level link', $route.fullPath === subItem.path && 'current']">
                {{ subItem.name }}
              </button>
            </div>
            <span class="text-empty" v-if="item.children && !item.children.length">
              暂无文章
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navMenus, logoUrl } from './../../const'
export default {
  data () {
    return {
      logoUrl,
      options: navMenus
    }
  },
  methods: {
    toLink (item = {}) {
      const { path } = item
      if (!path) {
        return
      }
      if (path.indexOf('/article') === 0 || path.indexOf('v=1.0') !== -1) {
        const link = `${location.origin}${path}`
        window.open(link, '_self')
        return
      }
      if (path.indexOf('/') === 0) {
        this.$router.push(path)
        return
      }
      this.$router.push(path).catch(() => { console.log() })
    },
    $_isSelected (item) {
      const { path } = this.$route
      if (path === item.path) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../../assets/css/variables.less";
@shadow: 0 0 @pm-sm rgba(0,0,0,.2);
@height-navbar: 80px;
.hx-nav {
  width: 100%;
  height: @height-navbar;
  padding: 0 @pm-bg * 2 0 @pm-lg * 2;
  position: fixed;
  display: flex;
  align-items: top;
  vertical-align: top;
  justify-content: space-between;
  top: 0;
  background-color: transparent;
  z-index: 12;
  transition: background-color .4s;

  &.scroll, &:hover {
    background-color: white;
  }
  .img-logo {
    display: inline-block;
    height: 100%;
    padding: @pm-sm*1.5 0;
    .icon {
      height: 100%;
      width: auto;
    }
  }
  .pad-options {
    display: inline-block;
    padding: 0;
    .link {
      cursor: pointer;
    }
    .pad-first-level {
      display: inline-block;
      position: relative;
      height: 100%;
      &:hover {
        background-color: #f2f2f2;
        .pad-second-level {
          display: flex;
          visibility: visible;
          opacity: 1.0;
        }
      }
      .btn-menu {
        border: none;
        display: inline-block;
        background-color: transparent;
        min-width: 120px;
        &.first-level {
          height: 100%;
          font-size: 20px;
          width: 120px;
          &::after {
            content: "";
            height: 4px;
            width: 50%;
            background-color: @color-light-blue;
            display: block;
            position: absolute;
            bottom: 4px;
            left: 25%;
            transition: transform .3s;
            transform: scale(0, 0);
          }
          &.selected::after {
            transform: scale(1, 1);
          }
        }
        &.third-level, &.second-level {
          font-size: 18px;
          height: 44px;
          width: 100%;
          padding-left: @pm-md;
        }
      }
    }

    .pad-second-level {
      position: fixed;
      top: @height-navbar;
      padding: @pm-md;
      width: 100%;
      display: flex;
      visibility: hidden;
      opacity: 0.0;
      transition: all .3s;
      left: 0;
      z-index: 10;
      background-color: white;
      border: 1px solid #f2f2f2;
      border-top: none;
      min-height: 400px;
      box-shadow: 0 6px 12px rgba(0,0,0,.2);
      .menu.second-level {
        position: relative;
        display: inline-block;
        flex: 1;
        & + .menu.second-level {
          margin-left: @pm-bg;
        }
        .btn-menu {
          text-align: left;
        }
        .btn-menu.second-level {
          color: @color-theme;
          font-weight: 600;
        }
      }
    }
    .pad-third-level {
      background-color: white;
      width: 100%;
      .btn-menu.third-level {
        font-size: 18px;
        border-radius: @pm-xs;
        &.current {
          background: linear-gradient(to right, @color-theme 0%, rgba(@color-theme, .0) 100%);
          color: white;
          &:hover {
            background-color: unset;
          }
        }
        &:hover {
            background-color: #f2f2f2;
        }
      }
    }
    .text-empty {
      font-size: 18px;
      padding: @pm-md;
      color: #999;
    }
  }
}
</style>
