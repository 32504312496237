<!--
 * @Description  : 证书展示
-->
<template>
  <div class="pad-certifications">
    <div class="row-images double">
      <el-image class="img-qualification" :src="`${$cos}article/img-doc-1.jpg`" :preview-src-list="images" alt="" />
      <el-image class="img-qualification" :src="`${$cos}article/img-doc-2.jpg`" :preview-src-list="images" alt="" />
    </div>
    <div class="row-images triple">
      <el-image class="img-qualification" :src="`${$cos}article/img-doc-3.jpg`" :preview-src-list="images" alt="" />
      <el-image class="img-qualification" :src="`${$cos}article/img-doc-4.jpg`" :preview-src-list="images" alt="" />
      <el-image class="img-qualification" :src="`${$cos}article/img-doc-5.jpg`" :preview-src-list="images" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      images: []
    }
  },
  mounted () {
    this.$_init()
  },
  methods: {
    $_init () {
      this.images = []
      for (let i = 1; i < 6; i++) {
        this.images.push(`${this.$cos}article/img-doc-${i}.jpg`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/variables.less";
.pad-certifications {

}
@media screen and (min-width: 720px) {
  .row-images {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: @pm-lg 0;
    & + .row-images {
      margin-bottom: @pm-lg * 3;
    }
    .img-qualification {
      border: 1px solid @color-gray-border;
      transition: transform .3s;
      cursor: zoom-in;
      &:hover {
        transform: scale(1.05, 1.05);
      }
    }
    &.double .img-qualification {
      height: 350px;
    }
    &.triple .img-qualification {
      height: 450px;
    }
  }
}

@media screen and (max-width: 720px) {
  .row-images {
    width: 100%;
    display: block;
    justify-content: space-between;
    padding: @pm-sm @pm-md;
    & + .row-images {
      margin-bottom: @pm-sm;
      padding-top: 0;
    }
    .img-qualification {
      border: 1px solid @color-gray-border;
      width: 100%;
      height: auto;
      & + .img-qualification {
        margin-top: @pm-sm;
      }
    }
  }
}
</style>
