<!--
 * @Description  : 底部版权信息
-->
<template>
  <footer class="footer-copyright">
    <span class="text copyright">深圳市安然安全科技有限公司 © {{ year }}</span>
    <a class="text btn-text" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023042751号-1</a>
    <span class="text btn-text" @click="$toRoute('/contact-us')">联系我们</span>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/variables.less";
.footer-copyright {
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: @color-theme;
  color: #ccc;
  cursor: default;
  font-size: 14px;
  .btn-text {
    color: #ccc;
    text-decoration: none;
  }
  .btn-text:hover {
    color: white;
    cursor: pointer;
  }
}

@media screen and (min-width: 720px) {
  .footer-copyright {
    height: 80px;
    display: flex;
    .text + .text {
      margin-left: @pm-lg;
    }
  }
}
@media screen and (max-width: 720px) {
  .footer-copyright {
    height: auto;
    display: block;
    padding: @pm-md;
    text-align: center;
    .text.copyright {
      display: block;
      width: 100%;
      line-height: 2;
    }
    .text.btn-text {
      & + .text.btn-text {
        margin-left: @pm-md;
      }
    }
  }
}
</style>
